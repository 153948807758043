/* eslint-disable @typescript-eslint/camelcase */
type companyRate = {
    branch_id: number
    company_id: number
    currency_id: null | number
    has_night_hours: number;
    id: number | null;
    minimum_call: number
    night_end: string
    night_start: string
    rates: rate[],
    job_id: null | number
}

function initCompanyRate(branchID: number, companyID:number, jobID?: number) {
    return {
        id: null,
        branch_id: branchID,
        company_id: companyID,
        currency_id: null,
        has_night_hours: 0,
        minimum_call: 0,
        night_end: "07:00",
        night_start: "19:00",
        job_id: jobID ? jobID : null,
        rates: [] as rate[]
    };
}

type rate = {
    id: number;
    standard_price: number;
    minimum_call: number;
    weekend_price: number;
    night_price: number;
    skill_id: number;
    company_rate_id: number;
    job_id: number | null
}

export { companyRate, rate, initCompanyRate };