
import {onMounted, defineComponent, computed, ref, watch} from "vue";
import { useStore } from "vuex";
import { useAbility } from "@casl/vue";
import JobClientRatesTable from "@/components/jobs/Misc/JobClientRatesTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Swal from "sweetalert2";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "client-rates-job-tab",
    components: {
      LoadingSpinner,
      JobClientRatesTable
    },
    setup() {
      const store = useStore();
      const ability = useAbility();
      const router = useRouter();
      const saveEvent = ref<boolean>(false);
      const isLoading = ref<boolean>(false);
      const isUpdating = ref<boolean>(false);
      const queue = ref<number[]>([]);
      const containerKey = ref<number>(0);
      const job = computed(
          () => store.getters['JobModule/item']
      );
      const clientRates = computed(
          () => store.getters['JobModule/getClientRates']
      );

      const addToQueue = (id: number) => {
        queue.value.push(id);
      }

      const removeFromQueue = (id: number) => {
        queue.value.splice(queue.value.indexOf(id), 1);
      }
      onMounted(() => {
        isLoading.value = true;
        store.dispatch('JobModule/getClientRatesForJob', job.value.id)
            .then(() => {
              isLoading.value = false;
            })
            .catch(err => {
              console.log(err);
            })
      });

      const triggerSaveEvent = () => {
        isUpdating.value = true;
        saveEvent.value = !saveEvent.value;
      }

      watch(() => queue.value.length, () => {
        if(queue.value.length == 0) {
          store.dispatch('JobModule/getClientRatesForJob', job.value.id)
            .then(() => {
              containerKey.value++;
              isUpdating.value = false;
              Swal.fire({
                position: "top-end",
                toast: true,
                icon: "success",
                title: `Record saved`,
                showConfirmButton: false,
                timer: 1000
              });
            })
        }
      });

      return {
        addToQueue,
        removeFromQueue,
        triggerSaveEvent,
        isUpdating,
        containerKey,
        clientRates,
        queue,
        isLoading,
        saveEvent,
        job
      }
    }
});
