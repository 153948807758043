
/* eslint-disable @typescript-eslint/camelcase */
import {computed, defineComponent, nextTick, onMounted, onUpdated, PropType, reactive, ref, watch} from "vue";
import { branch } from "@/components/jobs/Misc/types"
import {useStore} from "vuex";
import { jobRatesData } from "@/components/jobs/Misc/types";
import {companyRate, initCompanyRate} from "@/components/clients/misc/types";
import CurrenciesDropdown from "@/components/dropdown/CurrenciesDropdown.vue";
import Inputmask from "inputmask";

export default defineComponent({
  name: 'job-client-rates-table',
  props: {
    branch: {
      type: Object as PropType<branch>,
      required: true
    },
    saveEvent: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  components: {
    CurrenciesDropdown
  },
  setup(props, { emit }) {
    const store = useStore();
    const hasRate = ref<boolean>(false);
    const bulkEditList = ref<number[]>([]);
    const tableKey = ref<number>(0);
    const companyRate = ref<companyRate>();
    const bulkEdit = reactive({
      night_bonus: "",
      weekend_bonus: "",
      base_rate: "",
      minimum_call: ""
    });

    const job = computed(
        () => store.getters['JobModule/item']
    );
    const clientRates = computed<jobRatesData>(
        () => store.getters['JobModule/getClientRates']
    );
    const model = reactive({});
    const hasData = computed<boolean>(() => Object.values(model).length > 0);

    const nonJobRate = computed<companyRate|undefined>(
        () => clientRates.value.non_job_rates.find(rate => rate.branch_id == props.branch.id)
    );

    const handleBulkEditList = skill_id => {
      if (bulkEditList.value.includes(skill_id)) {
        bulkEditList.value = bulkEditList.value.filter(e => e != skill_id);
      } else {
        bulkEditList.value.push(skill_id);
      }
    };
    watch(() => props.saveEvent, () => {
      emit('addToQueue', props.branch.id as number);
      const payload = {
        companyRate: companyRate.value,
        rates: model,
        jobID: job.value.id
      }
      store.dispatch('NewRatesModule/saveJobRates', payload)
          .then(() => {
            emit('removeFromQueue', props.branch.id as number);
          });
    })
    const handleSelectAllRows = event => {
      if (event) {
        bulkEditList.value = [];
        Object.keys(model).forEach(key => {
          bulkEditList.value.push(parseInt(key));
        });
      } else {
        bulkEditList.value = [];
      }
      tableKey.value++;
    };
    const applyBulkEdit = () => {
      bulkEditList.value.forEach(skill => {
        model[skill].standard_price =
            bulkEdit.base_rate != ""
                ? bulkEdit.base_rate
                : model[skill].standard_price;
        model[skill].night_price =
            bulkEdit.night_bonus != ""
                ? bulkEdit.night_bonus
                : model[skill].night_price;
        model[skill].weekend_price =
            bulkEdit.weekend_bonus != ""
                ? bulkEdit.weekend_bonus
                : model[skill].weekend_price;
      });
      tableKey.value++;
    };

    const buildModel = (rate: companyRate | null) => {
      for (let i = 0; i < clientRates.value.skills.length; i++) {
        let standard_price = '' as string|number;
        let weekend_price = '' as string|number;
        let night_price = '' as string|number;
        let placeholder_standard = null as number|null;
        let placeholder_weekend = null as number|null;
        let placeholder_night = null as number|null;
        let existingRateID = null as number|null;
        if(rate){
          const existingRate = rate.rates.find(
              rate => rate.skill_id == clientRates.value.skills[i].id
          );
          if (existingRate) {
            standard_price = existingRate.standard_price;
            weekend_price = existingRate.weekend_price;
            night_price = existingRate.night_price;
            existingRateID = existingRate.id;
          } else {
            if(nonJobRate.value) {
              const skillRate = nonJobRate.value.rates.find(rate => rate.skill_id == clientRates.value.skills[i].id);
              if (skillRate) {
                placeholder_night = skillRate.night_price;
                placeholder_weekend = skillRate.weekend_price;
                placeholder_standard = skillRate.standard_price
              }
            }
          }
        } else {
          if(nonJobRate.value) {
            const skillRate = nonJobRate.value.rates.find(rate => rate.skill_id == clientRates.value.skills[i].id);
            if (skillRate) {
              placeholder_night = skillRate.night_price;
              placeholder_weekend = skillRate.weekend_price;
              placeholder_standard = skillRate.standard_price
            }
          }
        }
        model[clientRates.value.skills[i].id] = {
          skill_id: clientRates.value.skills[i].id,
          standard_price: standard_price,
          weekend_price: weekend_price,
          existing_rate_id: existingRateID,
          night_price: night_price,
          placeholder_standard: placeholder_standard,
          placeholder_weekend: placeholder_weekend,
          placeholder_night: placeholder_night
        };
      }
    }

    onMounted(() => {
      const rate = clientRates.value.rates.find(item => item.branch_id == props.branch.id);
      if(rate) {
        companyRate.value = rate;
        hasRate.value = true;
        buildModel(rate);
      } else {
        companyRate.value = initCompanyRate(props.branch.id as number, job.value.company.id as number, job.value.id as number);
        buildModel(null);
      }
    });
    watch(() => companyRate.value?.has_night_hours, async () => {
        await nextTick().then(() => {
          Inputmask({
            regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
          }).mask(`#night_hours_1_${props.branch.id}`);
          Inputmask({
            regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
          }).mask(`#night_hours_2_${props.branch.id}`);
        })
    })
    return {
      model,
      clientRates,
      bulkEdit,
      bulkEditList,
      companyRate,
      hasData,
      job,
      tableKey,
      handleSelectAllRows,
      applyBulkEdit,
      handleBulkEditList
    }
  }
});
